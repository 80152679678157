import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import Banner from '../components/Index/Banner'
import Current from '../components/Index/Current'
import Blog from '../components/Index/Blog'
import Areas from '../components/Index/Areas'
import { getLessPosts } from '../api/post'
import { getSettings } from '../api/settings'
import { getMenu } from '../api/menu'
import { getTime } from '../utils/get-time'

const ClientSideOnlyLazy = React.lazy(() =>
    import("@mirzalikic/react-cookie-notice")
)

const Index = () => {
    const [currentMenu, setCurrentMenu] = useState(null)
    const [areasMenu, setAreasMenu] = useState(null)
    const [posts, setPosts] = useState(null)
    const [settings, setSettings] = useState(null)
    const isSSR = typeof window === "undefined"

    useEffect(() => {
        (async () => {
            try {
                setPosts(null)
                const data = await getLessPosts()
                setPosts(data)
            } catch (error) {
                setPosts(null)
            }
            if (getTime() > 5 || (!localStorage.getItem('area-menu') || localStorage.getItem('area-menu') === '' || localStorage.getItem('area-menu') === '[]') || (!localStorage.getItem('settings') || localStorage.getItem('settings')=== '' || localStorage.getItem('settings') === '[]')) {
                try {
                    setSettings(null)
                    const data = await getSettings()
                    localStorage.setItem('settings', JSON.stringify(data))
                    setTimeout(() => {
                        localStorage.setItem('time', new Date().getTime())
                      }, "15000");
                    setSettings(data)
                } catch (error) {
                    setSettings(null)
                }
                try {
                    const data = await getMenu('Menú áreas')
                    localStorage.setItem('area-menu', JSON.stringify(data))
                    setAreasMenu(data)
                    const data2 = await getMenu('Menú actualidad')
                    localStorage.setItem('actualidad-menu', JSON.stringify(data2))
                    setCurrentMenu(data2)
                    setTimeout(() => {
                        localStorage.setItem('time', new Date().getTime())
                      }, "15000");

                } catch (error) {
                    setAreasMenu(null)
                    setCurrentMenu(null)
                }
            } else {
                setAreasMenu(JSON.parse(localStorage.getItem('area-menu')))
                setCurrentMenu(JSON.parse(localStorage.getItem('actualidad-menu')))
                setSettings(JSON.parse(localStorage.getItem('settings')))
            }
        })()
    }, [])

    if (!settings) return null

    return (
        <Layout
            settings={settings}
        >
            <Banner
                settings={settings}
            />
            <Blog
                posts={posts}
                //menu={areasMenu}
            />
              <Areas
                menu={areasMenu}
            />
            <Current
                menu={currentMenu}
            />
            {!isSSR && (
                <React.Suspense fallback={<div />}>
                    <ClientSideOnlyLazy
                        preferencesButtonText="Personalizar"
                        savePreferencesButtonText="Guardar mis preferencias"
                        acceptAllButtonText="Aceptar todo"
                        cookiePrefix="ayto-villafranca-de-los-barros-"
                        cookies={[
                            { name: 'necessary', checked: true, editable: false, default: true, title: 'Necesaria', text: 'Las cookies necesarias son cruciales para las funciones básicas del sitio web y el sitio web no funcionará de la forma prevista sin ellas. Estas cookies no almacenan ningún dato de identificación personal.' },
                            { name: 'fucntional', checked: false, editable: true, title: 'Funcional', text: 'Las cookies funcionales ayudan a realizar ciertas funcionalidades, como compartir el contenido del sitio web en plataformas de redes sociales, recopilar comentarios y otras características de terceros.' },
                            { name: 'analytics', checked: false, editable: true, title: 'Analítica', text: 'Las cookies analíticas se utilizan para comprender cómo interactúan los visitantes con el sitio web. Estas cookies ayudan a proporcionar información sobre métricas el número de visitantes, el porcentaje de rebote, la fuente de tráfico, etc.' },
                        ]}>
                        <h3>Valoramos tu privacidad</h3>
                        <p>Usamos cookies para mejorar su experiencia de navegación y analizar nuestro tráfico. Al hacer clic en “Aceptar todo” usted da su consentimiento a nuestro uso de las cookies.</p>
                    </ClientSideOnlyLazy>
                </React.Suspense>
            )}

        </Layout>
    )
}

export default Index