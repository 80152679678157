import React from 'react'
import { Link } from 'gatsby'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { format } from 'date-fns'
import {getConversion} from '../../api/images';


const Blog = ({ posts, menu }) => {
    return (
        <section className='blog-area py-4 bg-light'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Actualidad</h2>
                </div>
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className='swiper-container'
                >
                    {posts && posts.length > 0 ?
                        posts.map((post) => {
                            var media = post.media.filter(media => media.collection_name === 'featured_images');

                            return (
                                <SwiperSlide
                                    key={post.id}
                                >
                                    <div className='row p-0 m-0'>
                                        <div className='col-12 col-md-5 text-center' style={{height : '450px', overflow : 'hidden'}}>
                                            <div className='img-wrap round shadow' style={{ width: '450px' , height: '350px', overflow : 'hidden', margin : '0 auto'}}>
                                            {media.length > 0 &&
                                                <Link to={`/blog-details?slug=${post.slug}`}>
                                                    <img src={getConversion(media[0])} alt={post.title} style={{ height: '100%' }}/>
                                                </Link>
                                            }
                                            </div>

                                        </div>

                                        <div className='col-12 col-md-7'>
                                            <div className='post-content'>
                                              
                                                <h3>
                                                    <Link to={`/blog-details?slug=${post.slug}`}>
                                                        {post.title}
                                                    </Link>
                                                </h3>
                                                <ul className='post-meta d-flex align-items-center p-0'>
                                         
                                                    <li>
                                                        <i className='flaticon-calendar'></i> {  format(new Date(post.published_at), 'dd/MM/yyyy H:mm')}
                                                    </li>
                                                </ul>
                                                <p className='mt-3'>{post.summary}</p>
                                                <div dangerouslySetInnerHTML={{ __html: post.body? post.body.replace(/&nbsp;/g,'').substr(0, 500) + '...':'&nbsp;' }} />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                        :
                        <p className='text-center'>No hay noticias destacadas</p>
                    }
                </Swiper >
            </div >
          
        </section >
    )
}

export default Blog